@tailwind base;

@tailwind components;

@tailwind utilities;

@media print {
  html,
  body {
    display: none !important; /* hide whole page */
  }
}

html {
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
    'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'; /* 1 */
  line-height: 1.5; /* 2 */
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0; /* 2 */
}

/**
 * Inherit font-family and line-height from `html` so users can set them as
 * a class directly on the `html` element.
 */

body {
  font-family: inherit;
  line-height: inherit;
}

.g-recaptcha {
  width: 302px;
  margin: auto;
}

.react-datepicker__input-container input {
   width: 100%;
   background-origin: style;
   height: 100%;
}
